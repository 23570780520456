import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import { ApiDataListType, ApiDataResponseListLengthCheck, PAGE_SIZE } from "../../constants/options";
import VmButton from "../../components/shared-button";
import VmTable from "../../components/shared-table";
import VmModal from "../../components/shared-modal";
import Breadcrumb from "../../components/shared-breadcrumb";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { TD_FIRST, TD_LAST, TD_NORMAL } from "../../constants/style";
import { navigate } from "gatsby";
import { encryptedPassword } from "../../constants/settings";
import { isWeb } from "../../utilities/platform";


const UserOverviewPage = observer(() => {
  const { rootStore, studentStore, userStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // Determine is update or create
  const [targetUser, setTargetUser] = useState<any>();
  const [title, setTitle] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [birthDate, setBirthDate] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [cPassword, setCPassword] = useState<string>("");
  const [roldId, setRoldId] = useState<string>("0");
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    userStore.getOrganisationUserProfileList(PAGE_SIZE, 0);
  }, []);


  const onActionAdmin = () => {
    if ((!targetUser && password !== cPassword) ||
      (targetUser && password !== encryptedPassword && cPassword !== password)) {
      rootStore.notify(t('TWO_PASSWORDS_NOT_MATCH'), 'warning');
      return;
    };
    // if (password !== cPassword) {
    //   rootStore.notify(t('TWO_PASSWORDS_NOT_MATCH'), 'warning');
    //   return;
    // };
    let req: any = {
      title,
      firstName,
      lastName,
      middleName,
      birthDate,
      emailAddress,
      username,
      organisationId: isWeb && localStorage.USER_INFO ? JSON.parse(localStorage.USER_INFO).organisationId : 0,
      password: password === encryptedPassword ? oldPassword : password,
    }
    if (targetUser) {
      req.id = targetUser.id;
      userStore.updateOrganisationUserProfile(req).then(() => {
        rootStore.notify(t('User') + t('UPDATED_B'), 'success');
        userStore.getOrganisationUserProfileList(PAGE_SIZE, (page - 1) * PAGE_SIZE);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
    } else {
      userStore.updateOrganisationUserProfile(req).then(() => {
        rootStore.notify(t('User') + t('CREATED_B'), 'success')
        userStore.getOrganisationUserProfileList(PAGE_SIZE, 0);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'error'));
    }
  }

  const handleShowModal = (id?: number) => {
    if (id) {
      let target = userStore.userProfileList.data.find((a: any) => a.id == id);
      setTargetUser(target);
      setTitle(target.title);
      setFirstName(target.firstName);
      setLastName(target.lastName);
      setMiddleName(target.middleName);
      setBirthDate(target.birthDate.split('T')[0]);
      setEmailAddress(target.emailAddress);
      setUsername(target.username);
      setOldPassword(target.password);
      setPassword(encryptedPassword);
      setRoldId(target.role);
    }
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setTargetUser(null);
    setTitle("");
    setFirstName("");
    setLastName("");
    setMiddleName("");
    setBirthDate("");
    setEmailAddress("");
    setUsername("");
    setCPassword("");
    setOldPassword("");
    setPassword("");
    setRoldId("0");
  }

  const handleDeleteModal = (id: number) => {
    let target = userStore.userProfileList.data.find((a: any) => a.id == id);
    setTargetUser(target);
    setShowDeleteModal(true);
  }

  return (
    <ContentLayout
      pageName="Employer Users"
      pageHeading="Employer Users"
      breadCrumb={[
      ]}
      buttonLabel={t('ADD_A') + 'User Profile'}
      onClickAction={() => handleShowModal()}
    >
      <VmTable
        loading={userStore.loading}
        page={page}
        paginationCount={userStore.userProfileList.totalCount == 0 ? 1 : userStore.userProfileList.totalCount / PAGE_SIZE}
        onChangePagination={(event, pageNumber) => {
          setPage(pageNumber);
          userStore.getOrganisationUserProfileList(PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
        }}
        thead={["ID", t('NAME'), t('USERNAME'), t('BOD'), t('EMAIL'), t('ACTION')]}
        tbody={userStore.userProfileList.data.length > 0 &&
          userStore.userProfileList.data.map((admin, index) => (
            <>
              <Box sx={{ marginY: 1 }} />
              <tr key={`admin_${index}`}>
                <td className={TD_FIRST}>{index + 1}</td>
                <td className={TD_NORMAL}>{admin.firstName + " " + admin.lastName}</td>
                <td className={TD_NORMAL}>{admin.username}</td>
                <td className={TD_NORMAL}>{admin.birthDate.split('T')[0]}</td>
                <td className={TD_NORMAL}>{admin.emailAddress}</td>
                <td className={TD_LAST}>
                  {/* <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => navigate("/teacher/profile",
                      { state: { teacherId: admin.id } })}
                  >
                    {t('VIEW')}
                  </Button> */}
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => handleShowModal(admin.id)}
                  >
                    {t('EDIT')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteModal(admin.id)}
                  >
                    {t('DELETE')}
                  </Button>
                </td>
              </tr>
              <Box sx={{ marginY: 1 }} />
            </>
          ))}
      />

      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        width={500}
        title={targetUser ? t('UPDATE_A') + t('User Profile') : t('ADD_A') + t('User Profile')}
        buttonLabel={targetUser ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetUser ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={onActionAdmin}
        disabledCondition={title === "" || firstName === "" || lastName === "" || username === ""
          || password === "" || (!targetUser && cPassword === "") || emailAddress === ""
        }
      >
        <div className="grid grid-cols-2 gap-4">
          <TextField
            value={title}
            label={t('TITLE') + ' *'}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setTitle(value.target.value)}
          />
          <TextField
            value={username}
            label={t('USERNAME') + ' *'}
            variant="outlined"
            disabled={targetUser}
            sx={{ width: '100%' }}
            onChange={(value) => setUsername(value.target.value)}
          />
          <TextField
            value={firstName}
            label={t('FIRST_NAME') + ' *'}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setFirstName(value.target.value)}
          />
          <TextField
            value={lastName}
            label={t('LAST_NAME') + ' *'}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setLastName(value.target.value)}
          />
          <TextField
            value={middleName}
            label={t('MIDDLE_NAME')}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setMiddleName(value.target.value)}
          />
          <TextField
            value={birthDate}
            label={t('BOD') + ' *'}
            type="date"
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setBirthDate(value.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            value={password}
            label={t('PASSWORD') + ' *'}
            type="password"
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setPassword(value.target.value)}
          />
          <TextField
            value={cPassword}
            type="password"
            label={t('CONFIRM_PASSWORD') + ' *'}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setCPassword(value.target.value)}
          />
          <TextField
            value={emailAddress}
            label={t('EMAIL') + ' *'}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setEmailAddress(value.target.value)}
          />
          {/* <FormControl fullWidth>
            <InputLabel>{t('USER_ROLE')}</InputLabel>
            {
              permissionStore.roleDetailList.length > 0 &&
              <Select
                label={t('USER_ROLE')}
                sx={{ width: '100%', marginBottom: 2 }}
                value={roldId}
                onChange={onChangeRole}
              >
                <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
                {permissionStore.roleDetailList.map((role: any, index: number) => (
                  <MenuItem key={`role_select_${index}`} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            }
          </FormControl> */}
        </div>
      </VmModal>

      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        onClickConfirmedButton={() => userStore.deleteOrganisationUserProfile(targetUser.id).then(() => {
          if (targetUser && targetUser.id == JSON.parse(localStorage.USER_INFO).id) userStore.signOut();
          else {
            rootStore.notify(t('User Profile') + t('DELETED_B'), 'success')
            userStore.getOrganisationUserProfileList(PAGE_SIZE, 0);
            handleCloseModal();
          }
        }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'))}
      >
        <Typography sx={{ marginBottom: 2 }}>{t('DELETE_CHECK')}?</Typography>
        <Typography color="red" variant="body1">
          {targetUser && targetUser.id == JSON.parse(localStorage.USER_INFO).id ? 
          "WARNING: You are attempting to delete the currently logged in user! After deletion, you will be forced to log out"
            : "All relevant data will also be deleted."}
        </Typography>
      </VmModal>
    </ContentLayout>
  )
});

export default UserOverviewPage;
